<template lang="pug">
  .w-intro.widget.widget--screen-size
    img.w-intro__cover(src="@/assets/img/main.jpg")
    img.w-intro__caption(src="@/assets/svg/caption.svg")
</template>

<script>
export default {
  name: 'IntroWidget'
}
</script>

<style lang="scss" scoped>
  @import '../../sass/abstracts/_variables.scss';

  .w-intro {
    position: relative;
    z-index: 1;

    &__cover {
      min-width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__caption {
      position: absolute;
      width: 60%;
      right: 3rem;
      bottom: 5rem;
      opacity: 0;
      animation: fadeUp 1.4s cubic-bezier(0.25, 1, 0.5, 1) .3s forwards;
    }
  }
</style>
