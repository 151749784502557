<template lang="pug">
  .widget.timing-widget
    v-animate
      img.timing-widget__decor(
        src="@/assets/illustrations/flower-branch.svg")
    .timing-widget__content
      .title ТАЙМИНГ ДНЯ
      .timing-widget__item(
        v-for="(item, index) in items"
        :key="index")
        .timing-widget__item-time {{ item.time }}
        .timing-widget__item-label {{ item.label }}
    v-animate
      img.timing-widget__decor(
        src="@/assets/illustrations/flower-branch.svg")
</template>

<script>
export default {
  name: 'TimingWidget',

  data: () => ({
    items: [
      { time: '12:00', label: 'Церемония венчания' },
      { time: '15:00', label: 'Праздничный банкет' },
      { time: '17:30', label: 'Перерыв/Фотоссесия с гостями' },
      { time: '18:30', label: 'Продолжение программы' },
      { time: '20:30', label: 'Фейерверк. Нappy end' }
    ]
  })
}
</script>

<style lang="scss" scoped>
  .timing-widget {
    padding: 10rem 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;

    .title {
      text-align: center;
      margin-bottom: 5rem;
    }

    &__decor {
      width: 8.5rem;
    }

    &__content {
      padding: 4rem 0;
    }

    &__item {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 2.8rem;
      }

      &-time {
        width: 6rem;
        font-size: 2.3rem;
        font-family: 'Aire Bold Pro';
        margin-right: 1.5rem;
      }

      &-label {
        font-size: 1.6rem;
        font-family: 'Forum';
        text-transform: uppercase;
      }
    }
  }
</style>
