<template lang="pug">
  .w-address.widget.widget--white
    v-animate
      img.w-address__decor-right(
        src="@/assets/illustrations/flower-01.svg")
      img.w-address__decor-left(
        src="@/assets/illustrations/flower-01.svg")
    .w-address__content
      img.w-address__decor(
        src="@/assets/illustrations/flower-branch.svg")
      .title Венчание
      p.w-address__text г. ВИННИЦА, УЛ. КЕЛЕЦКАЯ, 91#[br] Церковь «СВЯТОЙ ТРОИЦЫ»#[br] начало в 12:00
      a.w-address__link(
        target="_blank"
        href="https://goo.gl/maps/F1fVqbyjkS4xc1oP9") ПОСМОТРЕТЬ НА КАРТЕ
      img.w-address__decor(
        src="@/assets/illustrations/flower-branch.svg")
      .title Банкет
      p.w-address__text г. ВИННИЦА, ул.Тарногородського, 42а#[br] РЕСТОРАН «DRIVE CLUB»#[br] НАЧАЛО В 15:00
      a.w-address__link(
        target="_blank"
        href="https://goo.gl/maps/cMheWPthpHm196Yx5") ПОСМОТРЕТЬ НА КАРТЕ
</template>

<script>
export default {
  name: 'AddressWidget'
}
</script>

<style lang="scss">
  @import '../../sass/abstracts/_variables.scss';

  .w-address {
    position: relative;

    &__decor {
      width: 8.5rem;
      margin-bottom: 2.4rem;
    }

    &__text {
      text-align: left;
      margin-top: 2rem;
      margin-bottom: 2.4rem;
    }

    &__link {
      display: inline-block;
      color: $color-primary;
      font-size: 1.4rem;
      padding: 1.4rem 2.2rem;
      border: .1rem solid $color-secondary;
      border-radius: .4rem;
      text-decoration: none;

      &:visited {
        color: $color-primary;
      }

      &:not(:last-child) {
        margin-bottom: 6.4rem;
      }
    }

    &__decor-right {
      position: absolute;
      height: 36rem;
      width: auto;
      right: -7rem;
      top: -14.2rem;
      // top: -25rem;
      transform: scaleX(-1) rotate(20deg);
      z-index: 0;
    }

    &__decor-left {
      position: absolute;
      left: -10rem;
      height: 36rem;
      width: auto;
      bottom: -29.3rem;
      // bottom: -38rem;
      transform: rotate(-5.15deg);
      z-index: 0;
    }

    &__content {
      padding: 10rem 4.5rem;
    }
  }
</style>
