<template lang="pug">
  .w-our-story.widget.widget--white
    .w-our-story__content
      .script-title Наша история
      v-animate
        .w-our-story__item
          img.w-our-story__avatar(src="@/assets/img/profile-01.png")
          .title Иван
          p.w-our-story__items-offset "Я понял, что любовь может многое...#[br] она в силе даже тысячи километров#[br] превратить в расстояние дыхания"
      v-animate
        img.w-our-story__decor-right(src="@/assets/illustrations/flower-01.svg")
        .w-our-story__item
          img.w-our-story__avatar(src="@/assets/img/profile-02.png")
          .title Галина
          p "Наша история — это не про любовь#[br] с первого взгляда. Это чувства, которые#[br] каждый день растут в геометрической#[br] прогрессии.. это костёр, который уже#[br] невозможно затушить..."
</template>

<script>
export default {
  name: 'OurStoryWidget'
}
</script>

<style lang="scss" scoped>
  .w-our-story {

    .title {
      margin-bottom: 2rem;
    }

    .script-title {
      margin-bottom: 3rem;
    }

    &__decor-right {
      position: absolute;
      top: 50rem;
      left: -13rem;
      height: 35.5rem;
      width: auto;
    }

    &__content {
      position: relative;
      z-index: 1;
      padding: 10rem 2.4rem;
      padding-top: 4rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__avatar {
      width: 15rem;
      margin-bottom: 2.8rem;
    }

    &__items-offset {
      margin-bottom: 7rem;
    }
  }
</style>
