<template lang="pug">
  .w-rsvp.widget
    v-animate
      img.w-rsvp__decor-right(src="@/assets/illustrations/flower-02.svg")
    h2.w-rsvp__title сможете ли Вы присоединиться #[br] к празднованию вместе с нами?
    .w-rsvp__block
      .w-rsvp__item(
        v-for="(item, index) in guests"
        :key="index")
        span.w-rsvp__item-label {{ item.name | name }}
        .w-rsvp__item-btns
          button.btn(
            :class="{ 'btn--active': item.answer }"
            @click="onUpdate(item, true)")
            | Да
          button.btn.btn--outline(
            :class="{ 'btn--active': item.answer === false }"
            @click="onUpdate(item, false)")
            | Нет
    template(v-if="showSecondBlock")
      h3.w-rsvp__title НА КАКИХ ЧАСТЯХ МЕРОПРИЯТИЯ#[br] ПЛАНИРУЕТЕ ПРИСУТСТВОВАТЬ?
      .w-rsvp__block
        .w-rsvp__item(
          v-for="(item, index) in parts"
          :key="index")
          span.w-rsvp__item-label {{ item.name }}
          .w-rsvp__item-btns
            button.btn(
              :class="{ 'btn--active': compareTo(item, true) }"
              @click="onSecondUpdate(item, true)")
              | Да
            button.btn.btn--outline(
              :class="{ 'btn--active': compareTo(item, false) }"
              @click="onSecondUpdate(item, false)")
              | Нет
</template>

<script>
import { initializeApp } from 'firebase/app'
import { query, doc, collection, where, getFirestore, setDoc, onSnapshot } from 'firebase/firestore'

const GUESTS = 'guests'

const firebaseConfig = {
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID
}

initializeApp(firebaseConfig)

const db = getFirestore()

export default {
  name: 'RSVPWidget',

  props: {
    invitation: Object
  },

  mounted () {
    this.initialize()
  },

  data: () => ({
    parts: [
      { name: 'ВЕНЧАНИЕ', answer: null, key: 'ceremony' },
      { name: 'БАНКЕТ', answer: null, key: 'banquet' }
    ],
    guests: []
  }),

  filters: {
    name (name) {
      const index = name.indexOf('(')
      if (index !== -1) {
        return name.substring(0, index - 1)
      }
      return name
    }
  },

  computed: {
    showSecondBlock () {
      return this.guests
        .some(item => item.answer === true)
    }
  },

  methods: {
    initialize () {
      const id = this.$store.getters.invitationId
      const q = query(collection(db, GUESTS), where('invitation_id', '==', id))
      onSnapshot(q, (querySnapshot) => {
          const items = []
          querySnapshot.forEach((docRef) => {
            const docData = docRef.data()
            const data = {
              id: docRef.id,
              ...docData
            }
            items.push(data)
          })
          this.guests = items
      })
    },

    onUpdate (item, answer) {
      const data = { ...item }
      data.answer = answer
      setDoc(doc(db, GUESTS, item.id), data)
    },

    onSecondUpdate (item, answer) {
      for (const guest of this.guests) {
        const data = { ...guest }
        data[item.key] = answer
        setDoc(doc(db, GUESTS, data.id), data, { merge: true })
      }
    },

    compareTo (item, value) {
      return this.guests
        .some(guest => guest[item.key] === value)
    }
  }
}
</script>

<style lang="scss" scoped>
  .w-rsvp {
    padding: 14rem 0rem;

    &__title {
      font-size: 2rem;
      font-family: 'Forum';
      line-height: 2.6rem;
      text-align: center;
      text-transform: uppercase;
    }

    &__decor-right {
      position: absolute;
      right: -20.2rem;
      bottom: -25.5rem;
      height: 40.6rem;
      width: 31rem;
      transform: rotate(-18deg);
      z-index: 1;
    }

    &__block {
      width: 34rem;
      margin: 0 auto;
      margin-top: 4rem;

      &:not(:last-child) {
        margin-bottom: 10rem;
      }
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }

      &-label {
        font-size: 1.8rem;
        text-transform: uppercase;
      }

      &-btns {
        display: flex;
        align-items: center;

        .btn {
          font-family: serif;
          min-width: 9rem;
          text-transform: unset;

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
</style>
