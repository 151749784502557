<template lang="pug">
  .w-info.widget
    v-animate
      img.w-info__decor-left(
        src="@/assets/illustrations/flower-02.svg")
      img.w-info__decor-right(
        src="@/assets/illustrations/flower-01.svg")
    .w-info__content
      .title {{ title }}
      .w-info__subtitle.script-title {{ guests }}
      p Сердечно приглашаем {{ you }}#[br]на торжество, посвящённое#[br] созданию нашей семьи,#[br] КОТОРОЕ СОСТОИТСЯ:
      p.script-title.mb-3 21 ноября 2021 года
      p И мы не представляем#[br] этот радостный день без Вас —#[br] близких и дорогих нам людей!
      img.w-info__decor(src="@/assets/illustrations/flower-branch.svg")
      p для вашего удобства, мы#[br] разработали сайт с подробной#[br] информацией про наш праздник,#[br] а ещё поделились частичкой#[br] нашей лавстори.
      img.w-info__decor(src="@/assets/illustrations/flower-branch.svg")
      p листайте дальше :)
</template>

<script>
export default {
  name: 'InfoWidget',

  props: {
    invitation: Object
  },

  computed: {
    title () {
      if (this.invitation && this.invitation.texts.caption) {
        return this.invitation.texts.caption
      }
      return 'Дорогие'
    },

    guests () {
      if (this.invitation) {
        return this.invitation.guests
          .map(item => {
            const index = item.name.indexOf('(')
            if (index !== -1) {
              return item.name.substring(0, index - 1)
            }
            return item.name
          })
          .join(', ')
          .replace(/,(?=[^,]*$)/, ' и')
      }
      return '-'
    },

    you () {
      if (this.invitation && this.invitation.texts.you) {
        return this.invitation.texts.you
      }
      return 'вас'
    }
  }
}
</script>

<style lang="scss" scoped>
  .w-info {
    padding: 10rem 0;

    &__content {
      position: relative;
      padding: 0 2rem;
      text-align: center;
      z-index: 1;
    }

    &__decor {
      width: 8.5rem;
      margin: 2.5rem auto;
    }

    &__decor-left {
      position: absolute;
      height: 33.4rem;
      width: 27rem;
      top: -.5rem;
      left: -12rem;
      transform: rotate(55deg);
    }

    &__decor-right {
      position: absolute;
      height: 32.1rem;
      width: auto;
      top: -2rem;
      right: -7.5rem;
      transform: scaleX(-1);
    }

    &__subtitle {
      margin-top: 1.4rem;
      margin-bottom: 2.8rem;
      line-height: 1.8;
    }
  }
</style>
