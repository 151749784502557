<template lang="pug">
  .w-telegram.widget.widget--white(id="telegram")
    v-animate
      img.w-telegram__decor-right(src="@/assets/illustrations/flower-01.svg")
      img.w-telegram__decor-left(src="@/assets/illustrations/flower-02.svg")
    .w-telegram__content
      img.w-telegram__profile(src="@/assets/img/profile-03.png")
      p.w-telegram__text Для быстрого обмена#[br] информацией, фото и видео#[br] между нашими гостями#[br] мы создали группу#[br] в Telegram
      a.btn(
        target="_blank"
        href="https://t.me/joinchat/T92XuYTKYSk4ZjVi")
        img.icon(src="@/assets/svg/telegram.svg")
        span добавиться
    .w-ps
      span made with
      img.w-ps__icon(src="@/assets/svg/heart.svg")
      span by
      a.link(
        target="_blank"
        href="https://instagram.com/invito.link") invito.link
</template>

<script>
export default {
  name: 'TelegramWidget'
}
</script>

<style lang="scss" scoped>
  @import '../../sass/abstracts/_variables.scss';

  .w-telegram {
    overflow: hidden;

    &__content {
      position: relative;
      padding: 15rem 2.4rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }

    &__profile {
      width: 15rem;
      margin-bottom: 5rem;
    }

    &__text {
      margin-bottom: 5rem;
    }

    &__decor-right {
      position: absolute;
      top: -14.5rem;
      right: -6rem;
      height: 48.6rem;
      width: auto;
      transform: scaleX(-1) rotate(21.31deg);
      opacity: .7;
    }

    &__decor-left {
      position: absolute;
      height: 46.5rem;
      width: auto;
      bottom: -2rem;
      left: -18.5rem;
      transform: rotate(-10deg);
      opacity: .7;
    }
  }
</style>
