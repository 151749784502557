<template lang="pug">
  .w-not-invitation-id.widget.widget--screen-size
    img.w-not-invitation-id__logo(src="@/assets/svg/invito.svg")
    .w-not-invitation-id__content
      img.w-not-invitation-id__heart(src="@/assets/svg/link-heart.svg")
      p чтобы открыть приглашение#[br]
        | воспользуйтесь своей уникальной#[br]
        | ссылкой =)
    .w-ps
      span made with
      img.w-ps__icon(src="@/assets/svg/heart.svg")
      span by
      a.link(
        target="_blank"
        href="https://instagram.com/invito.link") invito.link
</template>

<script>
export default {
  name: 'NoInvitationId'
}
</script>

<style lang="scss">
  @import '@/sass/abstracts/_variables.scss';

  .w-not-invitation-id {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-primary;

    &__logo {
      position: absolute;
      top: 3rem;
      width: 7rem;
      margin: 0 auto;
    }

    &__heart {
      width: 10rem;
      margin-bottom: 3rem;
    }

    &__content {
      color: $color-white;
      font-size: 2.2rem;
      font-weight: 300;
      text-align: center;
      line-height: 2.8rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .w-ps,
    .link {
      color: $color-white;
    }
  }
</style>
